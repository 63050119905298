<script setup lang="ts">
const carousel: Ref<HTMLElement | null> = ref(null)

const slideLeft = () => {
  if (!carousel.value)
    return

  const scrollAmount = carousel.value?.scrollLeft - carousel.value.offsetWidth / 2 + 100
  carousel.value?.scrollTo({
    left: scrollAmount,
    behavior: 'smooth',
  })
}

const slideRight = () => {
  if (!carousel.value)
    return

  const scrollAmount = carousel.value?.scrollLeft + carousel.value.offsetWidth / 2 - 100
  carousel.value?.scrollTo({
    left: scrollAmount,
    behavior: 'smooth',
  })
}
</script>

<template>
  <div class="container-8xl flex justify-between items-center mb-3">
    <div class="carousel-controls flex items-center">
      <UButton
        variant="outline"
        square
        :ui="{ rounded: 'rounded-full' }"
        color="forest"
        size="xl"
        aria-label="Previous"
        :padded="false"
        @click="slideLeft()"
      >
        <Icon name="mdi:chevron-left" class="text-2xl w-8 h-8" aria-hidden="true" />
      </UButton>
      <UButton
        variant="outline"
        square
        :ui="{ rounded: 'rounded-full' }"
        color="forest"
        size="xl"
        aria-label="Next"
        :padded="false"
        class="ml-2 mr-4"
        @click="slideRight()"
      >
        <Icon name="mdi:chevron-right" class="text-2xl w-8 h-8" aria-hidden="true" />
      </UButton>
      <slot name="button" />
    </div>
  </div>
  <div ref="carousel" class="px-4 pb-4 lg:px-0 carousel flex flex-nowrap gap-4 md:gap-4 mb-4 overflow-x-scroll no-scrollbar snap-x scroll-px-4 snap-mandatory">
    <slot />
  </div>
</template>
